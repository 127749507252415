import { testIsNonEmptyString, testIsObject, testIsString } from '../../../lib/test-and-assert/test-base';
import { globalLogger } from '../../helper/global-logger';

/**
 * @param {String} domain
 * @param {String} path
 *
 * @return {String}
 */
function joinDomainAndPath(domain, path) {
    if (domain.endsWith('/')) {
        domain = domain.slice(0, -1);
    }

    if (path.startsWith('/')) {
        path = path.slice(1);
    }

    return domain + '/' + path;
}

// this class is a wrapper around the global sat variable
//
// 'sat' is set in:
//
// sat-client/src/Resources/views/layouts/layout_signup.html.twig
// sat-client/src/Resources/views/layouts/layout_base.html.twig
//
// depending on which page you are it will contain different values
class SatGlobals {
    constructor() {
        if (!testIsObject(window.sat)) {
            globalLogger.log('get sat config fail', 'sat config does not exist');

            return {};
        }
    }

    /**
     * @return {SatConfig}
     */
    getConfig() {
        return window.sat;
    }

    /**
     * @return {string}
     */
    getProjectName() {
        return window.sat.project_name;
    }

    /**
     * @return {string}
     */
    getBaseDomain() {
        return window.sat.baseDomain;
    }

    /**
     * @param {string} [path='']
     *
     * @return {string}
     */
    getApiUrl(path) {
        if (!testIsString(window.sat.apiUrl)) {
            globalLogger.log('get sat api url fail', JSON.stringify(window.sat));

            return '';
        }

        // if the user specified a path then we return the full url
        if (testIsNonEmptyString(path)) {
            return joinDomainAndPath(window.sat.apiUrl, path);
        }

        return window.sat.apiUrl;
    }

    /**
     * @return {string}
     */
    getLocale() {
        if (!testIsNonEmptyString(window.sat.locale)) {
            globalLogger.log('get sat locale fail', JSON.stringify(window.sat));

            return 'en';
        }

        return window.sat.locale;
    }

    /**
     * @returns {string}
     */
    getStripePk() {
        return window.sat?.stripe_pk;
    }

    /**
     * @return {string}
     */
    getMainUrl() {
        if (!testIsNonEmptyString(window.sat.mainUrl)) {
            globalLogger.log('get sat main url fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.mainUrl;
    }

    /**
     * @return {string}
     */
    getResultUrl() {
        if (!testIsNonEmptyString(window.sat.resultUrl)) {
            globalLogger.log('get sat result url fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.resultUrl;
    }

    /**
     * @return {string}
     */
    getRegisterUrl() {
        if (!testIsNonEmptyString(window.sat.registerUrl)) {
            globalLogger.log('get sat register url fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.registerUrl;
    }

    /**
     * @return {string}
     */
    getToolsUrl() {
        if (!testIsNonEmptyString(window.sat.toolsUrl)) {
            return false;
        }

        return window.sat.toolsUrl;
    }

    /**
     * @return {string}
     */
    getSaleUrl() {
        if (!testIsNonEmptyString(window.sat.saleUrl)) {
            return false;
        }

        return window.sat.saleUrl;
    }

    /**
     * @return {string}
     */
    getPrivacyUrl() {
        if (!testIsNonEmptyString(window.sat.privacyUrl)) {
            globalLogger.log('get sat privacy url fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.privacyUrl;
    }

    /**
     * @return {string}
     */
    getPricingUrl() {
        if (!testIsNonEmptyString(window.sat.pricing)) {
            globalLogger.log('get sat pricing url fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.pricing;
    }

    /**
     * @return {string}
     */
    getAdTechUrl() {
        if (!testIsNonEmptyString(window.sat.adTechUrl)) {
            globalLogger.log('get sat privacy url fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.adTechUrl;
    }

    /**
     * @return {string}
     */
    getAccountUrl() {
        if (!testIsNonEmptyString(window.sat.accountUrl)) {
            globalLogger.log('get sat account url fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.accountUrl;
    }

    /**
     * @return {string}
     */
    getCapterraUrl() {
        if (!testIsNonEmptyString(window.sat.ratingCapterraUrl)) {
            globalLogger.log('get sat capterra url fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.ratingCapterraUrl;
    }

    /**
     * @return {string}
     */
    getG2Url() {
        if (!testIsNonEmptyString(window.sat.ratingG2Url)) {
            globalLogger.log('get sat g2 url fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.ratingG2Url;
    }

    /**
     * @return {string}
     */
    getCookieDomain() {
        if (!testIsNonEmptyString(window.sat.cookieDomain)) {
            globalLogger.log('get cookie domain fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.cookieDomain;
    }

    /**
     * @return {object}
     */
    getBrowserExtensionUrl() {
        if (!testIsObject(window.sat.browserExtension)) {
            globalLogger.log('get browser extension url fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.browserExtension;
    }

    /**
     * @return {object}
     */
    getLocalePages() {
        if (!testIsObject(window.sat.localePages)) {
            globalLogger.log('get locale pages fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.localePages;
    }

    /**
     *
     * @return {string}
     */
    getEnv() {
        return window.sat.env;
    }

    /**
     *
     * @return {string}
     */
    getSentryEnv() {
        return window.sat.senv;
    }

    /**
     * @return {boolean}
     */
    getEnableUserModalFlow() {
        return window.sat.enableUserModalFlow;
    }

    getTermsUrl() {
        if (!testIsNonEmptyString(window.sat.termsUrl)) {
            globalLogger.log('get termsUrl fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.termsUrl;
    }

    getImprintUrl() {
        if (!testIsNonEmptyString(window.sat.imprintUrl)) {
            globalLogger.log('get imprintUrl fail', JSON.stringify(window.sat));

            return '';
        }

        return window.sat.imprintUrl;
    }

    /**
     * @return {boolean}
     */
    getEnableFreeTrial() {
        return window.sat.enableFreeTrial;
    }

    getIsSale() {
        return window.sat.isSale;
    }

    getSaleEnd() {
        return window.sat.saleEnd;
    }

    getFreeTrialPeriod() {
        return window.sat.freeTrialPeriod;
    }

    /**
     * @return {boolean}
     */
    isVueUploaderFileListEnabled() {
        return window.sat.enableVueUploaderFileList;
    }

    /**
     * @return {boolean}
     */
    isWeekPassEnabled() {
        return window.sat.enableWeekPass ?? false;
    }

    /**
     * @return {boolean}
     */
    isRegionalPricingEnabled() {
        return window.sat.enableRegionalPricing ?? false;
    }

    /**
     * @return {boolean}
     */
    isCreditsEnabled() {
        return window.sat.enableCredits ?? false;
    }

    /**
     * @return {boolean}
     */
    isCreditsInAllSatsEnabled() {
        return window.sat.enableCreditsInAllSats ?? false;
    }

    /**
     * @return {boolean}
     */
    isReputationRatingEnabled() {
        return window.sat.enableReputationRating ?? false;
    }

    /**
     * @return {boolean}
     */
    isMultipleProductsEnabled() {
        return window.sat.enableMultipleProducts ?? false;
    }

    isCouponsEnabled() {
        return window.sat.enable_coupons ?? false;
    }

    /**
     * @return {String}
     */
    getHelpUrl() {
        return window.sat.helpUrl ?? '';
    }

    /**
     * @return {String}
     */
    getSentryDSN() {
        return window.sat.sdsn ?? 'none';
    }
}

let satGlobals = new SatGlobals();

export { satGlobals };
